import React from "react";

import './feestruct.css';

const Feesstructure = () => {
    return(
        <div className="container feestruct mb-5 mt-3 d-flex align-items-center flex-column">

          <h2 className="fee-head">Fees Structure</h2>

          <div className="fee-content w-100">


<h3 className="text-custom-green">Approved Intake & Fees Structure</h3>

{/* <h2 className="text-custom-green">Courses</h2> */}
<table className="table table-bordered border border-custom-green text-dark">
  <thead>
    <tr>
      <th>Courses</th>
      <th>Intake</th>
      <th>Duration of Course</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>B.Pharm</td>
      <td>100</td>
      <td>4 years</td>
    </tr>
    <tr>
      <td>D.Pharm</td>
      <td>60</td>
      <td>2 years</td>
    </tr>
  </tbody>
</table>

<h3 className="text-custom-green">First Year B.Pharm Fees Structure</h3>
<table className="table table-bordered border border-custom-green  text-dark table-responsive">
  <thead>
    <tr>
      <th>Category</th>
      <th>OPEN</th>
      <th>OBC/EBC/EWS</th>
      <th>VJNT/SBC/TFWS</th>
      <th>SC/ST</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Tution Fees</td>
      <td>72,727</td>
      <td>36,364</td>
      <td>0</td>
      <td>0</td>
    </tr>
    <tr>
      <td>Development Fees</td>
      <td>7,273</td>
      <td>7,273</td>
      <td>7,273</td>
      <td>0</td>
    </tr>
    <tr>
      <td><strong>Total</strong></td>
      <td>80,000</td>
      <td>43,637</td>
      <td>7,273</td>
      <td>0</td>
    </tr>
  </tbody>
</table>

<h3 className="text-custom-green">First Year D.Pharm Fees Structure</h3>
<table className="table table-bordered border border-custom-green  text-dark">
  <thead>
    <tr>
      <th>Category</th>
      <th>OPEN</th>
      <th>OBC/EBC/EWS</th>
      <th>NT/SBC/TFWS</th>
      <th>SC/ST</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Tution Fees</td>
      <td>54,545</td>
      <td>27,273</td>
      <td>0</td>
      <td>0</td>
    </tr>
    <tr>
      <td>Development Fees</td>
      <td>5,455</td>
      <td>5,455</td>
      <td>5,455</td>
      <td>0</td>
    </tr>
    <tr>
      <td><strong>Total</strong></td>
      <td>60,000</td>
      <td>32,728</td>
      <td>5,455</td>
      <td>0</td>
    </tr>
  </tbody>
</table>

<h3 className="text-custom-green">Direct Second Year B.Pharm Fees Structure</h3>
<table className="table table-bordered border border-custom-green  text-dark">
  <thead>
    <tr>
      <th>Category</th>
      <th>OPEN</th>
      <th>OBC/EBC/EWS</th>
      <th>NT/SBC/TFWS</th>
      <th>SC/ST</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Tution Fees</td>
      <td>72,727</td>
      <td>36,364</td>
      <td>0</td>
      <td>0</td>
    </tr>
    <tr>
      <td>Development Fees</td>
      <td>7,273</td>
      <td>7,273</td>
      <td>7,273</td>
      <td>0</td>
    </tr>
    <tr>
      <td><strong>Total</strong></td>
      <td>80,000</td>
      <td>43,637</td>
      <td>7,273</td>
      <td>0</td>
    </tr>
  </tbody>
</table>
</div>


        </div>
    )
}



export default Feesstructure;