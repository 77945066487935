import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/custom-colors.scss';


import brhlogo from './assets/brand/logo.png';
import jsslogo from './assets/brand/jssf.png';

import './Header.css';

const Header = () => {
    return (
        <div className="container header-parent mt-1">
            <div className="row d-flex justify-content-center align-items-center">
                {/* Left Logo */}
                <img 
                    src={brhlogo} 
                    className="logo col-3 col-md-2" 
                    alt="BRH Logo" 
                />
                
                {/* Center Content */}
                <div className="text-content text-center col-12 col-md-8">
                    <h4 className="text-danger fw-bold fs-small">JAI SHRI SIDDHIVINAYAK FOUNDATION'S</h4>
                    <h3 className="text-custom-green fw-bold fs-medium">B. R. Harne College of Pharmacy</h3>
                    <h4 className="text-warning fw-bold fs-smaller">Karav, Vangani (W), Ambernath, Dist : Thane - 421503</h4>
                    <h5 className="fs-smaller">
                        Approved By PCI New Delhi, DTE and Affiliated to 
                        University of Mumbai & MSBTE
                    </h5>
                    <h3 className="fs-smaller fw-bold text-custom-green blink">Eminence in Pharma Education</h3>
                </div>

                {/* Right Logo */}
                <img 
                    src={jsslogo} 
                    className="logo d-none d-md-block col-md-2" 
                    alt="JSS Logo" 
                />
            </div>
        </div>
    );
};

export default Header;
