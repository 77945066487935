import React from "react";

import './Tresdesk.css';

import tresimg from './assets/brand/treasurer.png'


const Tresdesk = () => {
    return (
        <div className="container President-parent mb-5 mt-3">
            <div className="row mt-4">


                <div className="col-12 col-md-6 presi-img">
                    <img src={tresimg} className="img-fluid rounded-circle" alt="President" />
                </div>

                <div className="col-12 col-md-6 presi-text">
                    <h2 className="text-custom-green from">From Treasurer's Desk</h2>
                    <h4 className="text-danger quote">"Courage and simplicity is the power to win"</h4>
                    <h2 className="text-custom-green name">Mr. Rushikesh M. Harne</h2>
                    <p className="text-danger post">Treasurer</p>
                    <p className="ad">Jai Shree Siddhivinayak Foundation Dombivli (West)</p>

                </div>


            </div>


            <div className="row align-items-center mt-4">
                {/* <div className="col-12 text-center mb-4 mb-md-0">
                </div> */}
                <div className="col-12">

                    <p className="fs-p text-justify">
                    Having been haunted by the socioeconomic & ethical transformation of our country, the foundation stone of the educational complex of B.R. Harne Educational Campus under the canopy of Jai Shri Siddhivinayak Foundation was laid in the year 2007. It is growing year by year. The mission of establishing the educational campus is to impart quality education by including innovative courses & creating a learner-friendly environment. I believe that the students completing various courses will not only have ample job opportunities in India and abroad but can also look forward to becoming entrepreneurs & researchers. I extend a hearty & warm welcome to you at B. R. Harne College of Pharmacy, which will definitely shape your mind & character. Wish you All the Best for your future prospects.</p>
                </div>
            </div>
        </div>
    );
};


export default Tresdesk;