import React from "react";

// Import your images
import img1 from './assets/WD/Women_s day celebration at B R Harne college of Architecture on 9 th March 2023(WEBP).webp';
import img2 from './assets/WD/Women_s day celebration at B R Harne college of Architecture on 9 th March 2023(WEBP)_1.webp';
import img3 from './assets/WD/Women_s day celebration at B R Harne college of Architecture on 9 th March 2023(WEBP)_2.webp';
import img4 from './assets/WD/Women_s day celebration at B R Harne college of Architecture on 9 th March 2023(WEBP)_3.webp';
import img5 from './assets/WD/Women_s day celebration at B R Harne college of Architecture on 9 th March 2023(WEBP)_4.webp';
import img6 from './assets/WD/Women_s day celebration at B R Harne college of Architecture on 9 th March 2023(WEBP)_5.webp';

const Womenday = () => {
  return (
    <div className="container mb-5 mt-5">
        <h1 className="text-custom-green text-center mb-4">World Women's Day ( 08/03/2024)</h1>
      {/* Paragraphs with text-dark class */}
      <p className="text-dark">
        International Women's Day (IWD), observed annually on March 8th, is a global celebration honoring the social, economic, cultural, and political achievements of women. It also serves as a call to action for accelerating gender equality worldwide.
      </p>
      <p className="text-dark">
        Originating from the early 20th century women's movements, IWD commemorates the struggles and successes of women in various fields. It was established in 1911 following a proposal by Clara Zetkin during the International Conference of Working Women.
      </p>
      <p className="text-dark">
        The significance of IWD lies in its role as a platform for advocacy, awareness-raising, and activism. Each year, it is celebrated with a specific theme focusing on different aspects of gender equality and women's empowerment.
      </p>
      <p className="text-dark">
        Numerous events, campaigns, and initiatives are organized globally to commemorate IWD, including rallies, conferences, workshops, and social media campaigns. These activities aim to celebrate women's achievements, raise awareness about gender inequality, and advocate for policy changes that promote women's rights and opportunities.
      </p>
      <p className="text-dark">
        Despite progress, challenges such as discrimination, violence, and gender gaps persist. However, IWD represents an opportunity for reflection and action, inspiring individuals and communities to champion gender equality and support initiatives that empower women economically, socially, and politically.
      </p>
      <p className="text-dark">
        In conclusion, International Women's Day is a momentous occasion to celebrate women's contributions to society and renew our commitment to gender equality. It reminds us of the ongoing struggle for women's rights and the importance of collective action in creating a more just and inclusive world for all.
      </p>
      <p className="text-dark">
        Organized panel discussions and workshops on topics related to gender equality, women's empowerment.
      </p>
      <p className="text-dark">
        Invited guest speakers, including activists, scholars, and professionals, to deliver talks on gender issues, leadership, and women's rights.
      </p>
      <p className="text-dark">
        Host interactive quizzes, debates, essay competitions, or hackathons centered around gender equality and women's empowerment themes.
      </p>

      {/* Row with six col-md-6 columns containing images */}
      <div className="row">
        <div className="col-12 col-md-4 mb-3">
          <img src={img1} alt="Event1" className="img-fluid" />
        </div>
        <div className="col-12 col-md-4 mb-3">
          <img src={img2} alt="Event2" className="img-fluid" />
        </div>
        <div className="col-12 col-md-4 mb-3">
          <img src={img3} alt="Event3" className="img-fluid" />
        </div>
        <div className="col-12 col-md-4 mb-3">
          <img src={img4} alt="Event4" className="img-fluid" />
        </div>
        <div className="col-12 col-md-4 mb-3">
          <img src={img5} alt="Event5" className="img-fluid" />
        </div>
        <div className="col-12 col-md-4 mb-3">
          <img src={img6} alt="Event6" className="img-fluid" />
        </div>
      </div>
    </div>
  );
};

export default Womenday;
