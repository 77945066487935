import React from "react";
import './Govbody.css'

const GovBody = () => {
    return (
        <div className="container govbody mt-4 mb-4 d-flex flex-column align-items-center">
            <h2 className="text-custom-green text-center mb-5 gov-text">Constitution Of Governing Body</h2>
            <table className="table table-bordered border border-custom-green  table-striped text-center ">
                <thead className="thead-dark text-start">
                    <tr>
                        <th>Sr. No</th>
                        <th>Name</th>
                        <th>Designation</th>
                    </tr>
                </thead>
                <tbody className="text-start">
                    {[
                        ["1", "Hon. Shri. Mangesh B. Harne", "Chairman"],
                        ["2", "Mr. Rushikesh M. Harne", "Member"],
                        ["3", "Mr. Shailesh Sakpal", "Member"],
                        ["4", "Nominee University", "Member"],
                        ["5", "Nominee DTE Mumbai(Ex-Officer)", "Member"],
                        ["6", "Nominee MSBTE", "Member"],
                        ["7", "Mr. Mahesh D. Pawar", "Member (From Industry)"],
                        ["8", "Dr. Sankest Dharashivkar", "Member (From Academics)"],
                        ["9", "Mrs. Preeti S. Chauhan", "Member"],
                        ["10", "Ms. Srushti R. Kambale", "Member"],
                        ["11", "Mrs. Dr. Mukund G. Tawar", "Principal & Member Secretary"]
                    ].map(([srNo, name, designation], index) => (
                        <tr key={index}>
                            <td>{srNo}</td>
                            <td>{name}</td>
                            <td>{designation}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default GovBody;
