// import logo from './logo.svg';
// import './App.css';


import { Routes, Route} from 'react-router-dom';


import Header from  './components/Header';
import MyNavbar from  './components/MyNavbar';
import Carousel from './components/Carousel';
import About from './components/About';
import Updates from './components/Updates';

import Footer from './components/Footer';
import Topbtn from './components/Topbtn';


import PO from './components/PO';
import GovBody from './components/GovBody';
import CDC from './components/CDC';
import AdBpharm from './components/AdBpharm';
import AdDpharm from './components/AdDpharm';

import Presidesk from './components/Presidesk';
import Tresdesk from './components/Tresdesk';
import Prindesk from './components/Prindesk';



import CodeConduct from './components/CodeConduct';
import Bpharmcourse from './components/Bpharmcourse';
import Dpharmcourse from './components/Dpharmcourse';
import Feesstructure from './components/Feesstructure';



import Bpharmsyllabus from './components/Bpharmsyllabus';
import Dpharmsyllabus from './components/Dpharmsyllabus';
import ActivityList from './components/ActivityList';





import Blooddon from './components/Blooddon';
import Womenday from './components/Womenday';
import Cultureevent from './components/Culturalevent';
import Shivevent from './components/Shivcelebration';

import Leprosy from './components/Leprosy';
import WorldLeprosy from './components/WorldLeprosy';
import WorldAids from './components/WorldAids';

import Dashara from './components/Dashara';
import Fresher from './components/Fresher';
import Navratri from './components/Navratri';
import Pharmaday from './components/Pharmaday';
import Orientation from './components/Orientation';
import Teachers from './components/Teachers';
import Gudhipadwa from './components/Gudhipadwa';
import Guestlecture from './components/Guestlecture';
import GuestPharmacology from './components/GuestPharmacology';
import GuestPharmaceutics from './components/GuestPharmaceutics';
import GuestMicrobio from './components/GuestMicrobio';
import GuestPharmacognosy from './components/GuestPharmacognosy';
import Contact from './components/Contact';
import Notif from './components/Notif';
import AdForm from './components/Form';
import VisMis from './components/VisMis';




const Layout = ({ children }) => (
  <>
  <Header />
  <MyNavbar />
  <Notif />
  <div className="main-content">
    {children}
  </div>
  <Topbtn/>
  <Footer />
  </>
);

const App = ()  => {
  return (


      <Layout>

      <Routes>
          <Route path="/" element={
            <>
            <Carousel/>
            <About/>
            <VisMis />
            <Updates />


            
            </>

          } />

          <Route path="/PO" element={<PO/>} />
          <Route path="/GovBody" element={<GovBody/>} />
          <Route path="/CDC" element={<CDC/>} />
          <Route path="/AdBpharm" element={<AdBpharm/>} />
          <Route path="/AdDpharm" element={<AdDpharm/>} />


          <Route path="/President desk" element={<Presidesk/>} />
          <Route path="/Treasurer desk" element={<Tresdesk/>} />
          <Route path="/Principal desk" element={<Prindesk/>} />

          <Route path="/CodeConduct" element={<CodeConduct/>} />


          <Route path="/Bpharmcourse" element={<Bpharmcourse/>} />
          <Route path="/Dpharmcourse" element={<Dpharmcourse/>} />
          <Route path="/Fees structure" element={<Feesstructure/>} />


          <Route path="/Bpharm Syllabus" element={<Bpharmsyllabus/>} />
          <Route path="/Dpharm Syllabus" element={<Dpharmsyllabus/>} />

          <Route path="/Activity" element={<ActivityList/>} />
          <Route path="/Blooddon" element={<Blooddon/>} />
          <Route path="/Womens Day" element={<Womenday/>} />
          <Route path="/Cultural Event" element={<Cultureevent/>} />
          <Route path="/Shiv Event" element={<Shivevent/>} />
          <Route path="/Leprosy" element={<Leprosy/>} />
          <Route path="/World Leprosy" element={<WorldLeprosy/>} />
          <Route path="/World Aids" element={<WorldAids/>} />

          <Route path="/Dashara" element={<Dashara/>} />
          <Route path="/Fresher" element={<Fresher/>} />

          <Route path="/Navratri" element={<Navratri/>} />
          <Route path="/Pharmaday" element={<Pharmaday/>} />
          <Route path="/Orientation" element={<Orientation/>} />
          <Route path="/Teachers" element={<Teachers/>} />
          <Route path="/Gudhipadwa" element={<Gudhipadwa/>} />



          <Route path="/Guestlecture" element={<Guestlecture/>} />
          <Route path="/GuestPharmacology" element={<GuestPharmacology/>} />
          <Route path="/GuestPharmaceutics" element={<GuestPharmaceutics/>} />
          <Route path="/Guest Microbiology" element={<GuestMicrobio/>} />
          <Route path="/Guest Pharmacognosy" element={<GuestPharmacognosy/>} />




          <Route path="/Contact" element={<Contact/>} />

          <Route path="/Form" element={<AdForm/>} />





        </Routes>

      </Layout>

  );
};


export default App;
