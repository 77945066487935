import React from "react";

import img1 from './assets/nav/navr1.jpg';
import img2 from './assets/nav/navr2.jpg';
import img3 from './assets/nav/navr3.jpg';
import img4 from './assets/nav/navr4.jpg';
import img5 from './assets/nav/navr5.jpg';
import img6 from './assets/nav/navr6.jpg';
import img7 from './assets/nav/navr7.jpg';
import img8 from './assets/nav/navr8.jpg';





const Navaratri = () => {

    const imageStyle = {
        width: '100%',
        height: '400px',
        objectFit: 'cover',
    };
    return(
        <div className="container mt-5 mb-5">
            <h1 className="text-custom-green text-center mb-4">
            Navaratri Celebration (20/10/2023)

            </h1>
            <p className="text-dark">
            Navratri, a festival deeply embedded in the cultural and spiritual fabric of many communities, especially within India, is a vibrant celebration of the divine feminine. This nine-night festival, as its name suggests (‘Nav’ meaning nine and ‘Ratri’ meaning nights in Sanskrit), is dedicated to worshipping the nine forms of Goddess Durga. Each day is associated with an incarnation of the goddess, celebrated with its unique rituals and significance, making Navratri a time of intense spiritual rejuvenation and communal harmony. This essay delves into the essence of Navratri, exploring its historical roots, varied practices, and the underlying messages of empowerment and devotion.
                            </p>

                            <p className="text-dark">
                            Navratri was celebrated on 20/10/2023 at B.R.Harne college of pharmacy celebration begins with worshipping the goddess saraswati ,speech,various dance performances with related to garba .
                            </p>


            <div className="row">
                <div className="col-12 col-md-3 mb-4">
                    <img src={img1} alt="" className="img-fluid" style={imageStyle}/>

                </div>
                <div className="col-12 col-md-3 mb-4">
                    <img src={img2} alt="" className="img-fluid" style={imageStyle}/>

                </div>
                <div className="col-12 col-md-3 mb-4">
                    <img src={img3} alt="" className="img-fluid" style={imageStyle}/>

                </div>
                <div className="col-12 col-md-3 mb-4">
                    <img src={img4} alt="" className="img-fluid" style={imageStyle}/>

                </div>
                <div className="col-12 col-md-3 mb-4">
                    <img src={img5} alt="" className="img-fluid" style={imageStyle}/>

                </div>
                <div className="col-12 col-md-3 mb-4">
                    <img src={img6} alt="" className="img-fluid" style={imageStyle}/>

                </div>
                <div className="col-12 col-md-3 mb-4">
                    <img src={img7} alt="" className="img-fluid" style={imageStyle}/>

                </div>
                <div className="col-12 col-md-3 mb-4">
                    <img src={img8} alt="" className="img-fluid" style={imageStyle}/>

                </div>
            </div>

        </div>
    );
};



export default Navaratri;