import React, { useState, useEffect, useRef } from 'react';
import './MyNavbar.css';


import brochure from './assets/Brochurebrh.pdf';

const MyNavbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 991);
  const [dropdownsOpen, setDropdownsOpen] = useState({
    team: false,
    event: false,
    program: false,
  });
  const [isFixed, setIsFixed] = useState(false); // Track if navbar should be fixed

  const menuRef = useRef(null);

  const toggleMenu = () => setIsMenuOpen((prev) => !prev);

  const toggleDropdown = (dropdown) => {
    setDropdownsOpen((prev) => ({
      ...prev,
      [dropdown]: !prev[dropdown],
    }));
  };

  const handleHover = (dropdown, isHovered) => {
    if (!isMobile) {
      setDropdownsOpen((prev) => ({
        ...prev,
        [dropdown]: isHovered,
      }));
    }
  };

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 991);
    
    // Set a fixed position after scrolling down 200px
    const handleScroll = () => {
      const threshold = 200; // Set the threshold in pixels
      setIsFixed(window.pageYOffset > threshold); // Fix navbar after scroll > threshold
    };
  
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };
  
    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll); // Add scroll event listener
    document.addEventListener('mousedown', handleClickOutside);
  
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll); // Cleanup scroll listener
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  

  return (
    <div className="top-container">
      <nav
        className={`navbar navbar-expand-lg navbar-dark ${isFixed ? 'fixed-top' : ''}`}
        ref={menuRef}
      >
        <div className="container-fluid">
          {/* Navbar toggler button */}
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleMenu}
            aria-controls="navbarNav"
            aria-expanded={isMenuOpen}
            aria-label="Toggle navigation"
          >
            <i className={`fas ${isMenuOpen ? 'fa-times' : 'fa-bars'}`}></i>
          </button>

          {/* Navbar links */}
          <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`} id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link" href="/">Home</a>
              </li>
              {/* About Us Dropdown */}
              <li
                className="nav-item dropdown"
                onClick={() => toggleDropdown('about')}
                onMouseEnter={() => handleHover('about', true)}
                onMouseLeave={() => handleHover('about', false)}
              >
                {/* To ignore, add // eslint-disable-next-line */}
{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a className="nav-link dropdown-toggle" href="#" id="aboutDropdown">
                  About
                </a>
                {dropdownsOpen.about && (
                  <ul className="dropdown-menu show">
                    <li><a className="dropdown-item" href="/PO">PO's</a></li>
                    <li><a className="dropdown-item" href="/GovBody">Governing Body</a></li>
                    <li><a className="dropdown-item" href="/CDC">College Development Commitee</a></li>
                    {/* To ignore, add // eslint-disable-next-line */}
{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <li><a className="dropdown-item" href="">Regulating Body</a></li>
                    <li><a className="dropdown-item" href={brochure} download="brochurebrh.pdf">Download Brochure</a></li>

                  </ul>
                )}
              </li>
              <li
                className="nav-item dropdown"
                onClick={() => toggleDropdown('admission')}
                onMouseEnter={() => handleHover('admission', true)}
                onMouseLeave={() => handleHover('admission', false)}
              >
                {/* To ignore, add // eslint-disable-next-line */}
{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a className="nav-link dropdown-toggle" role="button" id="admissionDropdown">
                  Admission
                </a>
                {dropdownsOpen.admission && (
                  <ul className="dropdown-menu show">
                    <li><a className="dropdown-item" href="/AdBpharm">B.Pharm</a></li>
                    <li><a className="dropdown-item" href="/AdDPharm">D.Pharm</a></li>

                  </ul>
                )}
              </li>
              {/* Team Dropdown */}
              <li
                className="nav-item dropdown"
                onClick={() => toggleDropdown('team')}
                onMouseEnter={() => handleHover('team', true)}
                onMouseLeave={() => handleHover('team', false)}
              >
                {/* To ignore, add // eslint-disable-next-line */}
{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a className="nav-link dropdown-toggle" href="#" id="teamDropdown">
                  Administration
                </a>
                {dropdownsOpen.team && (
                  <ul className="dropdown-menu show">
                    <li><a className="dropdown-item" href="/President desk">From President's Desk</a></li>
                    <li><a className="dropdown-item" href="/Treasurer desk">From Treasurer's Desk</a></li>
                    <li><a className="dropdown-item" href="/Principal desk">From Principal's Desk </a></li>
                  </ul>
                )}
              </li>
              {/* Event Dropdown */}
              <li
                className="nav-item dropdown"
                onClick={() => toggleDropdown('event')}
                onMouseEnter={() => handleHover('event', true)}
                onMouseLeave={() => handleHover('event', false)}
              >
                {/* To ignore, add // eslint-disable-next-line */}
{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a className="nav-link dropdown-toggle" role="button" id="eventDropdown">
                  Departments
                </a>
                {dropdownsOpen.event && (
                  <ul className="dropdown-menu show">
                    <li><a className="dropdown-item" href="/CodeConduct">Code of Conduct</a></li>
                  </ul>
                )}
              </li>
              {/* Program Dropdown */}
              <li
  className="nav-item dropdown"
  onClick={() => toggleDropdown('program')}
  onMouseEnter={() => handleHover('program', true)}
  onMouseLeave={() => handleHover('program', false)}
>
  {/* To ignore, add // eslint-disable-next-line */}
{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
  <a className="nav-link dropdown-toggle" role="button" id="Dropdown">
    Courses
  </a>
  {dropdownsOpen.program && (
    <ul className="dropdown-menu show">
      <li><a className="dropdown-item" href="/Bpharmcourse">B. Pharm</a></li>
      <li><a className="dropdown-item" href="/Dpharmcourse">D. Pharm</a></li>
      <li><a className="dropdown-item" href="/Fees Structure">Fees Structure</a></li>

      <li
        className="dropdown-submenu"
        onMouseEnter={() => handleHover('fees', true)}
        onMouseLeave={() => handleHover('fees', false)}
        onClick={(e) => {
          e.stopPropagation(); // Prevent parent dropdown toggle
          toggleDropdown('fees');
        }}
      >
        {/* To ignore, add // eslint-disable-next-line */}
{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a className="dropdown-item dropdown-toggle" href="#">Syllabus</a>
        {dropdownsOpen.fees && (
          <ul className="dropdown-menu show">
            <li><a className="dropdown-item" href="/Bpharm Syllabus">B. Pharm</a></li>
            <li><a className="dropdown-item" href="/Dpharm Syllabus">D. Pharm</a></li>
          </ul>
        )}
      </li>
    </ul>
  )}
</li>


              <li
                className="nav-item dropdown"
                onClick={() => toggleDropdown('courses')}
                onMouseEnter={() => handleHover('courses', true)}
                onMouseLeave={() => handleHover('courses', false)}
              >
                {/* To ignore, add // eslint-disable-next-line */}
{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a className="nav-link dropdown-toggle" role="button" id="coursesDropdown">
                  Events
                </a>
                {dropdownsOpen.courses && (
                  <ul className="dropdown-menu show">
                    <li><a className="dropdown-item" href="/Activity">Activities</a></li>
                    <li><a className="dropdown-item" href="/Guestlecture">Guest Lectures</a></li>

                  </ul>
                )}
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/Contact">Contact</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default MyNavbar;
