import React from "react";


const Dpharmsyllabus = () => {
    return(
        <div className="container mt-5 mb-5 d-flex flex-column align-items-center">
            <h2 className="text-custom-green text-center bs-text mb-4">Syllabus of D.Pharm</h2>
            <div className="row bs-table">
            <table class="table table-striped border border-custom-green  table-bordered">
  <thead>
    <tr>
      <th>First Year D.Pharm</th>
      <th>Second Year D.Pharm</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>1. Pharmaceutics-I Theory and Practical</td>
      <td>1. Pharmacology Theory and Practical</td>
    </tr>
    <tr>
      <td>2. Pharmaceutical Chemistry-I Theory and Practical</td>
      <td>2. Community Pharmacy & Management Theory and Practical</td>
    </tr>
    <tr>
      <td>3. Pharmacognosy Theory and Practical</td>
      <td>3. Biochemistry & Clinical Pathology Theory and Practical</td>
    </tr>
    <tr>
      <td>4. Biochemistry & Clinical Pathology Theory and Practical</td>
      <td>4. Pharmacotherapeutics Theory and Practical</td>
    </tr>
    <tr>
      <td>5. Human Anatomy & Physiology Theory and Practical</td>
      <td>5. Hospital & Clinical Pharmacy Theory and Practical</td>
    </tr>
    <tr>
      <td>6. Social Pharmacy Theory and Practical</td>
      <td>6. Pharmacy Law & Ethics Theory</td>
    </tr>
  </tbody>
</table>

            </div>

        </div>
    );
};



export default Dpharmsyllabus;