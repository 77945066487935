import React from "react";


import './Footer.css';

import logo from './assets/brand/logo.png';
import bizologo from './assets/brand/biz_logo.png';

const Footer = () => {
    return (
        <div className="container-fluid footer">

        <div className="container footer-parent">

            <div className="logo col-12 d-flex align-items-center">
                <img src={logo} alt="" className="img-fluid"/>
            </div>
            <div className="row mt-3">
                <div className="col-12 col-lg-4 d-flex flex-column oath">

                    <h2 className="text-custom-green footer-head-text">Pharmacist Oath</h2>
                    <p>I swear by the code of ethicsof Pharmacy Council of India, in relation to the communityand shall act as an integralpart of health care team. <br />
                    I shall uphold the laws and standards governing my profession. <br />
                    I shall striveto perfect and enlarge my knowledge to contribute to the advancement of pharmacy and public health. <br />
                    I shall follow the system which I consider best for Pharmaceutical care and counseling of patients. <br />
                    I shall endeavorto discover and manufacture drugs of quality to alleviatesufferings of humanity. I shall hold in confidence the knowledge gained about the patients in connection with my professional practice and never divulgeunless compelled to do so by the law. <br />
                    I shall associate with organizations having their objectives for betterment of the profession of Pharmacy and make contribution to carry out the work of those organizations. <br />
                    While I continueto keep this oath unviolated, may it be granted to me to enjoy life and the practice of pharmacy respectedby all, at all times!Should I trespassand violate this oath, may the reversebe my lot!</p>

                </div>
                <div className="col-12 col-lg-4 d-flex flex-column useful-links">
                    <h2 className="text-custom-green footer-head-text">Useful Links</h2>
                    <a href="https://www.pci.nic.in/">PCI</a>
                    <a href="https://www.dtemaharashtra.gov.in/">DTE</a>
                    <a href="https://mu.ac.in/">University of Mumbai</a>
                    <a href="https://msbte.org.in/">MSBTE</a>
                    <a href="http://www.naac.gov.in/index.php/en/">NAAC</a>
                    <a href="https://www.nbaind.org/">NBA</a>
                </div>

                <div className="col-12 col-lg-4 d-flex flex-column contact-footer">
                    <h2 className="text-custom-green footer-head-text">Contact Details</h2>
                    <p><span>Address:</span><br />Karav, Vangani (W), Ambernath, Dist : Thane - 421503</p>
                    <p>Website: www.brharnepharmacy.in</p>
                    <p>Phone : 7218113531 | 9665000861</p>
                    <p>Email : brharnepharmacy@gmail.com</p>
                    <p><span>Head Office:</span> <br />201, 2nd floor, Sunrise Galaxy, Ganashyam Gupte Road Vishnunagar, Near Railway Station, Dombivli(W)</p>
                    <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3250.102619174963!2d73.31090186335308!3d19.119178546783836!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7be1ef98ff0d7%3A0x13bbcb218bddcf1e!2sB.%20R.%20Harne%20College%20of%20Pharmacy!5e0!3m2!1sen!2sin!4v1733318313576!5m2!1sen!2sin"
                            width="100%"
                            height="300"
                            style={{ border: '0', borderRadius: '8px' }}
                            allowFullScreen=""
                            loading="lazy"
                            title="Google Maps"
                        ></iframe>

                </div>

            </div>

            <div className="footer-bottom mt-3 mb-3">
                <div className="col-12 text-center">
                    <h3 className="text-custom-green footer-head-text follow">Follow us on</h3>
                    <div className="social-icons d-flex justify-content-center mb-3">
                    <a href="https://www.google.com/search?sca_esv=3efbb99c78a025e4&rlz=1C1GCEA_enIN1118IN1118&sxsrf=ADLYWIIkaJqKLESMTPDd1U6A7fqyhbk1RQ:1723887263600&q=B.+R.+Harne+Colleg+Of+Pharmacy&ludocid=1421953452306452254&lsig=AB86z5VWjWrHE3u6skIThOyGRW3A&shndl=-1&shem=lsde,vslcea&source=sh/x/loc/act/m1/1&kgs=a316c357a05cee87" target="_blank" rel="noopener noreferrer" className="social-link">
                            <i className="fab fa-google fa-2x"></i>
                        </a>
                        <a href="https://www.facebook.com/brhcop/" target="_blank" rel="noopener noreferrer" className="social-link">
                            <i className="fab fa-facebook fa-2x"></i>
                        </a>
                        <a href="https://www.instagram.com/brharnecollegeofpharmacy/?igsh=MWFwenczZ3VtY2p5eA%3D%3D" target="_blank" rel="noopener noreferrer" className="social-link">
                            <i className="fab fa-instagram fa-2x"></i>
                        </a>
                        <a href="https://www.linkedin.com/in/b-r-harne-college-of-pharmacy-536127325?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-linkedin fa-2x text-dark"></i>
                            </a>
                    </div>
                    <p className="text-center">© Copyright 2023 B. R. Harne College Of Pharmacy. All Rights Reserved</p>
                    <p className="text-center">Designed and Managed by</p>
                    <a href="http://bizonance.in/"><img src={bizologo} alt="" className="img-fluid bizo" /></a>
                </div>
            </div>
                


        </div>
        </div>

    );
};

export default Footer;