import React from "react";


import img1 from './assets/Gl/GL4-img1.jpg';
import img2 from './assets/Gl/GL4-img2.jpg';
import img3 from './assets/Gl/GL4-img3.jpg';








const GuestPharmacology = () => {
    return(
        <div className="container mb-5 mt-5">
            <h1 className="text-custom-green text-center mb-4">
            Guest Lecture Report on Pharmacognosy
            </h1>
            <p className="text-dark">
            On behalf of B R Harne College of Pharmacy, Vangani, a guest lecture on Pharmacognosy was organized on Monday, 02/09/2024. The session was conducted by Mrs, Jeeja Franklin Pananchery Assistant Professor of Pharmacognosy at D.Y.Patil deemed to be school of pharmacy, Navi Mumbai.
            </p>
            <li className="fs-4">Objective</li>
            <p className="text-dark">
            The objective of the lecture was to describe a direction for the students acquiring new knowledge & skills of pharmacognosy, for their university & GPAT exam preparation.
            </p>
            <li className="fs-4">Key Highlights</li>
            <p className="text-dark">
            The speaker explained about what is pharmacognosy, crude drug, gives information about primary and secondary metabolites, what are herbal preparation & complete extraction process for crude drugs.
            </p>
            <p className="text-dark">Exam Preparation: The speaker emphasized the importance of pharmacognosy concepts from both university and GPAT exam perspectives, offering tips on how to tackle related questions.</p>
            {/* <p className="text-dark">University and GPAT Exam Guidance Specific tips and key topics related to pharmaceutics for university and GPAT exams were discussed, helping students focus on essential areas for better preparation.</p> */}
            
            <li className="fs-4">Learning Outcomes</li>
            <p className="text-dark">
            The session improved their understanding of how to prepare for pharmacology-related questions in university and GPAT exams.
            </p>
            <p className="text-dark">
            The lecture was highly informative and helped enhance students' grasp of pharmacology concepts, contributing to both academic and professional growth.
            </p>
            <li className="fs-4">Vote of Thanks</li>
            <p className="text-dark">
            The vote of thanks was delivered by Mrs. Shraddha Khadse, expressing gratitude to Mrs. Jeeja Franklin Pananchery for her insightful presentation.
            </p>



            <div className="row">
                <div className="col-12 col-md-4 mb-4">
                    
                    <img src={img1} className="img-fluid" alt="" />

                </div>
                <div className="col-12 col-md-4 mb-4">

                    <img src={img2} className="img-fluid" alt="" />

                </div>
                <div className="col-12 col-md-4 mb-4">

                    <img src={img3} className="img-fluid" alt="" />

                </div>
            </div>
        </div>

    );
};



export default GuestPharmacology;