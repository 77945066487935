import React from "react";

import './PO.css'





const PO = () => {
    return(
        <div className="container mt-5 mb-5 PO-parent d-flex align-items-center flex-column">
            <h2 className="text-custom-green text-center PO-text">Program Outcomes (PO's)</h2>

            <div>
                  <h2 className="text-custom-green text-start">1. Pharmacy Knowledge</h2>
                  <p className="text-dark">
                    Possess knowledge and comprehension of the core information associated with the profession of pharmacy, including biomedical sciences; pharmaceutical sciences; behavioural, social, and administrative pharmacy sciences; and manufacturing practices.
                  </p>

                  <h2 className="text-custom-green text-start">2. Thinking Abilities</h2>
                  <p className="text-dark">
                    Utilise the principles of scientific inquiry, thinking analytically, clearly and critically, while solving problems and making decisions during daily practice. Find, analyse, evaluate and apply information systematically and shall make defensible decisions.
                  </p>

                  <h2 className="text-custom-green text-start">3. Planning Abilities</h2>
                  <p className="text-dark">
                    Demonstrate effective planning abilities including time management, resource management, delegation skills and organisational skills. Develop and implement plans and organise work to meet deadlines.
                  </p>

                  <h2 className="text-custom-green text-start">4. Leadership skills</h2>
                  <p className="text-dark">
                    Understand, analyse and communicate the value of their professional roles in society (e.g. health care professionals, suppliers of pharmaceuticals, promoters of health, educators, business managers, employers, employees) through consideration of historical, social, economic and political issues.
                  </p>

                  <h2 className="text-custom-green text-start">6. The Pharmacist and society</h2>
                  <p className="text-dark">
                    Apply reasoning informed by the contextual knowledge to assess societal, health, safety, legal, and cultural issues and the consequent responsibilities relevant to the professional pharmacy practice.
                  </p>

                  <h2 className="text-custom-green text-start">7. Environment and sustainability</h2>
                  <p className="text-dark">
                    Understand the impact of the professional pharmacy solutions in societal and environmental contexts, and demonstrate the knowledge of, and need for sustainable development.
                  </p>

                  <h2 className="text-custom-green text-start">8. Ethics</h2>
                  <p className="text-dark">
                    Honour personal values and apply ethical principles in professional and social contexts. Demonstrate behaviour that recognises cultural and personal variability in values, communication and lifestyles. Use ethical frameworks, apply ethical principles while making decisions, and take responsibility for the outcomes associated with the decisions.
                  </p>

                  <h2 className="text-custom-green text-start">9. Communication</h2>
                  <p className="text-dark">
                    Possess knowledge and comprehension of the core information associated with the profession of pharmacy, including biomedical sciences; pharmaceutical sciences; behavioural, social, and administrative pharmacy sciences; and manufacturing practices.
                  </p>

                  <h2 className="text-custom-green text-start">10. Modern tool usage</h2>
                  <p className="text-dark">
                    Learn, select, and apply appropriate methods and procedures, resources, and modern pharmacy-related computing tools with an understanding of the limitations.
                  </p>

                  <h2 className="text-custom-green text-start">11. Life-long learning</h2>
                  <p className="text-dark">
                    Recognize the need for, and have the preparation and ability to engage in independent and life-long learning in the broadest context of technological change. Self-assess and use feedback effectively from others to identify learning needs and to satisfy these needs on an ongoing basis.
                  </p>
            </div>

        </div>
    );
};



export default PO;