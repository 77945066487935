import React from "react";

import img1 from './assets/ot/Screenshot_2024-04-10-13-30-01-834-edit_com.instagram.android.jpg';
import img2 from './assets/ot/Screenshot_2024-04-10-13-30-14-826-edit_com.instagram.android.jpg';





const Orientation = () => {

    const imageStyle = {
        width: '100%',
        height: '600px',
        // objectFit: 'cover',
    };
    return(
        <div className="container mt-5 mb-5">
            <h1 className="text-custom-green text-center mb-4">
            Orientation program (14/09/2023 )

            </h1>
            <p className="text-dark">
            Orientation refers to introductory programs or events organized by colleges or universities to help new students become acquainted with campus life, academic policies, support services, and other important aspects of college.
            </p>
            <p className="text-dark">
            College orientation usually occurs before the start of the academic term or semester and aims to ease the transition for incoming students. These orientations can include campus tours, information sessions, academic advising, registration for classes, social activities, and opportunities to meet faculty, staff, and fellow students.
            </p>
            <p className="text-dark">
            Orientation programme was held at 14/09/2023 in B.R.Harne college of pharmacy ,vangani it was started by conducting saraswati puja ,speech ,welcome dance and refreshments. All parents,students ,teaching and non-teaching staff attended the event.   
            </p>

            <div className="row">
                <div className="col-12 col-md-6 mb-4">
                    <img src={img1} alt="" className="img-fluid" style={imageStyle}/>

                </div>
                <div className="col-12 col-md-6 mb-4">
                    <img src={img2} alt="" className="img-fluid" style={imageStyle}/>

                </div>
            </div>

        </div>
    );
};



export default Orientation;