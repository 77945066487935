import React from "react";

import './Prindesk.css';

import prinimg from './assets/brand/principal.png'


const Prindesk = () => {
    return (
        <div className="container President-parent mb-5 mt-3">
            <div className="row mt-4">

                <div className="col-12 col-md-6 presi-img">
                    <img src={prinimg} className="img-fluid rounded-circle" alt="President" />
                </div>

                <div className="col-12 col-md-6 presi-text prin-text">
                    <h2 className="text-custom-green from">From Principal's Desk</h2>
                    <h4 className="text-danger quote">"Success can not be achieved without continuous efforts"</h4>
                    <h2 className="text-custom-green name">Dr. Mukund G. Tawar</h2>
                    <p className="text-danger post">Principal</p>
                    <p className="text-danger post">M. Pharm., Ph.D.</p>

                    <p className="ad">Jai Shree Siddhivinayak Foundation Dombivli (West)</p>

                </div>


            </div>


            <div className="row align-items-center mt-4">
                {/* <div className="col-12 text-center mb-4 mb-md-0">
                </div> */}
                <div className="col-12">

                    <p className="fs-p text-justify">
                    An unwavering commitment to outstanding education and research in pharmaceutical sciences and technology defines the success of any College. We are now moving into our mission of educating pharmacists and pharmaceutical scientists who possess the necessary knowledge, skills, and attitudes to become leaders in pharmacy and pharmacy-related practice, research and education. We are initiating a number of initiatives, including aggressively enhancing our infrastructure to support world-class teaching, learning, and research; providing innovative solutions to the Pharmaceutical industry; building solid, lasting relationships with other institutions & industries and playing an important leadership role. We have state-of-the-art infrastructure supporting separate facilities for D. Pharm, and B. Pharm courses. An intimate relationship with the pharmaceutical industry helps in exposing our students to the solutions to practical problems of industry. We have planned to regularly organize events of national and international stature independently as well as in collaboration with other renowned academic institutes, professional societies, and pharmaceutical industries. Participation in such events will give our students an edge over their competitors and shape their personalities. We are poised to enter a period of great opportunity, and face our challenges with genuine talent and resolve. We are ready to work even harder to strengthen our reputation as the place Where Pharmaceutical scientists and technologists Learn and Discover. We welcome students, who want to make difference in education and in the professional world of pharmaceutical sciences!.  </p>
                </div>
            </div>
        </div>
    );
};


export default Prindesk;