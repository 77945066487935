import React from "react";

import img1 from './assets/phy/phy1.jpg';
import img2 from './assets/phy/phy2.jpg';
import img3 from './assets/phy/phy3.jpg';





const Pharmaday = () => {

    const imageStyle = {
        width: '100%',
        height: '600px',
        objectFit: 'cover',
    };
    return(
        <div className="container mt-5 mb-5">
            <h1 className="text-custom-green text-center mb-4">
            Pharmacist Day (25/09/2023)

            </h1>
            <p className="text-dark">
            Pharmacist Day is an observance dedicated to recognizing the vital role pharmacists play in healthcare and society. Pharmacists are highly trained professionals who specialize in medication management, dispensing prescriptions, providing patient education, and ensuring the safe and effective use of medications.
            </p>

            <p className="text-dark">
            Pharmacist Day provides an opportunity for patients and communities to express gratitude and appreciation for the expertise, professionalism, and dedication demonstrated by pharmacists in delivering quality pharmaceutical care. It serves as a reminder of the importance of pharmacists as integral members of the healthcare team and advocates for patient well-being.
            </p>
            <p className="text-dark">
            Pharmacist day was celebrated on 25/09/2023 at B.R.Harne college of pharmacy vangani, it was celebrated by organing poster presentation ,various games, speech, dancing and singing performance of students for enthusiasm. funfair was organize to encourage the students .the programme was ended with refreseshment and vote of thanks .
            </p>

            <div className="row">
                <div className="col-12 col-md-4 mb-4">
                    <img src={img1} alt="" className="img-fluid" style={imageStyle}/>

                </div>
                <div className="col-12 col-md-4 mb-4">
                    <img src={img2} alt="" className="img-fluid" style={imageStyle}/>

                </div>
                <div className="col-12 col-md-4 mb-4">
                    <img src={img3} alt="" className="img-fluid" style={imageStyle}/>

                </div>
            </div>

        </div>
    );
};



export default Pharmaday;