import React, { useState, useEffect, useCallback } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import './Carousel.css';

import slideone from './assets/slide/1.jpeg';
import slidetwo from './assets/slide/2.jpeg';
import slidethree from './assets/slide/3.jpeg';

const Carousel = () => {
  const [activeIndex, setActiveIndex] = useState(-1); // Start with no active slide
  const [initialized, setInitialized] = useState(false); // Track initialization for animation

  // Array of images and captions
  const slides = [
    {
      img: slideone,
      label: 'First slide label',
      caption: 'Some representative placeholder content for the first slide.',
    },
    {
      img: slidetwo,
      label: 'Second slide label',
      caption: 'Some representative placeholder content for the second slide.',
    },
    {
      img: slidethree,
      label: 'Third slide label',
      caption: 'Some representative placeholder content for the third slide.',
    },
  ];

  const handleNext = useCallback(() => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % slides.length);
  }, [slides.length]);

  // const handlePrev = useCallback(() => {
  //   setActiveIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
  // }, [slides.length]);

  // Handle auto-sliding
  useEffect(() => {
    const initTimeout = setTimeout(() => {
      setActiveIndex(0);
      setInitialized(true);
    }, 500);

    const interval = setInterval(() => {
      if (initialized) {
        handleNext();
      }
    }, 3000);

    return () => {
      clearTimeout(initTimeout);
      clearInterval(interval);
    };
  }, [initialized, handleNext]);

  return (
    <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
      <div className="carousel-indicators">
        {slides.map((_, index) => (
          <button
            key={index}
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to={index}
            className={index === activeIndex ? 'active' : ''}
            aria-current={index === activeIndex ? 'true' : 'false'}
            aria-label={`Slide ${index + 1}`}
            onClick={() => setActiveIndex(index)}
          ></button>
        ))}
      </div>

      <div className="carousel-inner">
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`carousel-item ${index === activeIndex ? 'active' : ''}`}
          >
            <img src={slide.img} className="d-block w-100" alt={slide.label} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
