import React from "react";

import list23 from './assets/F.Y.D. Pharm Merit List 22-23.pdf';
import list24 from './assets/F.Y.D.Pharm Merit list 23-24.pdf';


const AdDpharm = () => {
    return(
        <div className="container ad mt-5 mb-5 d-flex flex-column align-items-center">
        <h2 className="text-custom-green text-center adb-text">D.Pharm Merit List</h2>
<table className="table table-striped border border-custom-green  text-center">
<thead className="thead-dark">
  <tr>
    <th>First Year D.Pharm</th>
    <th>Year</th>
    <th>Show</th>
    <th>Download</th>
  </tr>
</thead>
<tbody>
  <tr>
    <td>F.Y.B.Pharm Merit List</td>
    <td>2022-2023</td>
    <td>
      <a href={list23}>Show list</a>
    </td>
    <td>
      <a href={list23} download="F.Y.B.Pharm Merit List 22-23.pdf">Download list</a>
    </td>
  </tr>
  <tr>
    <td>F.Y.B.Pharm Merit List</td>
    <td>2023-2024</td>
    <td>
      <a href={list24}>Show list</a>
    </td>
    <td>
      <a href={list24} download="F.Y.B.Pharm Merit List 23-24.pdf">Download list</a>
    </td>
  </tr>
</tbody>
</table>
{/* </div> */}

    </div>
    )
}



export default AdDpharm;