import React from "react";
import './About.css';


import about from './assets/slide/1.jpeg'

const About = () => {
    return (
        <div className="container-fluid about">

        <div className="container about-parent d-flex align-items-center flex-column">
            <h3 className="text-center aboutus mb-4">About Us</h3>
            <div className="row align-items-center">
                {/* Text Content */}
                <div className="col-12 col-md-6 text-content">
                    <p>
                    Jai Shri Siddhivinayak Foundation was established in 2007 by Hon'ble Sri. Mangeshji Harne, with the motive to propagate and spread education in the downtrodden, rural masses of the Western region of the state of Maharashtra. Education enables an individual to be independent, and self-sufficient financially besides raising productivity for Society. The Campus is spread over 33 Acres of land away from the hustle & bustle of busy city traffic, in the peaceful area of Vangani near the banks of Ulhas river. A spacious, well- planned, well-lit, well-ventilated building surrounded by the lush green campus is many neighbors' envy. B. R. Harne College of Pharmacy is established in 2022 with approvals from PCI, University of Mumbai, DTE, and MSBTE for the conduction of B.Pharm. and D. Pharm. Courses. The college has well-equipped laboratories, spacious classrooms, a gym and games area, highly qualified faculties, medical emergency facility, transport facility, and all required facilities for the benefit of students.
                    </p>
                </div>
                {/* Image Content */}
                <div className="image-content col-12 col-md-6 d-flex align-items-start justify-content-center">
                    <img src={about} alt="JSS Logo" className="img-fluid" />
                </div>
            </div>
        </div>
        </div>

    );
};

export default About;
