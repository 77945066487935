import React, { useState } from "react";


import './guest-text.css';



const Guestlecture = () => {
  const [filter, setFilter] = useState("all");

  // Define your activities with multiple links for each event
  const activities = [
    {
      date: "lecture",
      event: "Guest Lecture Report on Pharmacology",
      links: [
        { label: "Read More", url: "/GuestPharmacology" },
      ],
    },
    {
      date: "lecture",
      event: "Guest Lecture Report on Pharmaceutics",
      links: [
        { label: "Read More", url: "/GuestPharmaceutics" },
      ],
    },
    {
      date: "lecture",
      event: "Guest Lecture Report on Pharmaceutical microbiology",
      links: [
        { label: "Read More", url: "/Guest Microbiology" },

      ],
    },
    {
      date: "lecture",
      event: "Guest Lecture Report on Pharmacognosy",
      links: [
        { label: "Read More", url: "/Guest Pharmacognosy" },
      ],
    },
  ];

  // Filter logic
  const filteredActivities =
    filter === "all"
      ? activities
      : activities.filter((item) => item.date.includes(filter));

  return (
    <div className="container mt-5 mb-5 guest">
      <div className="d-flex flex-column align-items-center">
        <h2 className="text-custom-green text-center guest-text">Guest Lectures And Seminars</h2>
        {/* Buttons */}
        <div className="mb-4">
          <button
            className="btn me-2"
            onClick={() => setFilter("lecture")}
          >
            Guest Lectures
          </button>
          <button
            className="btn me-2"
            onClick={() => setFilter("2024")}
          >
            Seminars
          </button>
        </div>

        {/* Activity List */}
        <table className="table table-striped border border-custom-green">
          <tbody>
            {filteredActivities.map((activity, index) => (
              <tr key={index}>
                {/* <td>{activity.date}</td> */}
                <td>{activity.event}</td>
                <td>
                  {activity.links.map((link, idx) => (
                    <div key={idx}>
                      <a href={link.url} className="text-custom-green">
                        {link.label}
                      </a>
                    </div>
                  ))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Guestlecture;
