import React from "react";

// Import images and video related to the event
import img1 from './assets/Cul/cul1.mp4';  // Video file
import img2 from './assets/Cul/cul2.jpg';
import img3 from './assets/Cul/cul3.jpg';
import img4 from './assets/Cul/cul4.jpg';
import img5 from './assets/Cul/cul5.jpg';
// import img6 from './assets/Cul/cul6.jpg';
import img7 from './assets/Cul/cul7.jpg';
import img8 from './assets/Cul/cul8.jpg';
import img9 from './assets/Cul/cul9.jpg';
import img10 from './assets/Cul/cul10.jpg';
import img11 from './assets/Cul/cul11.jpg';
import img12 from './assets/Cul/cul12.jpg';
import img13 from './assets/Cul/cul13.jpg';
import img14 from './assets/Cul/cul14.jpg';

const Culturalevent = () => {
    const imageStyle = {
        width: '100%',
        height: '500px', // Adjusted height to 300px
        objectFit: 'cover',
    };

    return (
        <div className="container mt-5 mb-5">
            <h1 className="text-custom-green text-center mb-4">Cultural Event (05/03/2024)</h1>
            <p className="text-dark">
                Cultural events represent the heartbeat of societies, pulsating with the rhythms of tradition, innovation, and collective expression. These gatherings serve as focal points where individuals converge to celebrate heritage, explore diversity, and forge connections. At the college level, such events hold particular significance, offering students a platform to engage with multifaceted identities, broaden their perspectives, and cultivate a sense of belonging within the campus community.
            </p>
            <p className="text-dark">
                MUDRA 2K24, held from the 5th to the 16th of March, was a vibrant celebration of talent, sportsmanship, and creativity at our college.
            </p>
            <p className="text-dark">
            The schedule was packed with a diverse range of events, catering to various interests and passions of the students. From intense cricket matches for both boys and girls to strategic games like carrom and chess, the sports competitions kicked off the festivities with high energy and enthusiasm.
            </p>

            <p className="text-dark">
            As the days progressed, the excitement continued with thrilling matches in kho-kho, volleyball, badminton, and kabbadi, showcasing the athletic prowess of our students. Alongside sports, artistic skills were put to the test with competitions like rangoli, elocution, nail art, and mehendi, adding a touch of creativity and tradition to the event.
            </p>


            <p className="text-dark">
            The cultural days were a highlight of MUDRA 2K24, with themes ranging from Traditional Day to Bollywood Day/Character Day. These themed days brought out the spirit of camaraderie and creativity among the students, as they dressed up in traditional attire or impersonated their favorite movie characters.
            </p>

            <p className="text-dark">
            On Freshers Day, the campus was abuzz with excitement as new students were welcomed with warmth and hospitality. The day was filled with fun activities, dance performances, and singing competitions, creating memories that would last a lifetime.
            </p>

            <p className="text-dark">
            Finally, the grand finale of MUDRA 2K24 was the Annual Day celebration, where the entire college came together for a spectacular showcase of talent and glamour. The highlight of the evening was the Fashion Show, where students strutted down the runway in stylish ensembles, captivating the audience with their confidence and charisma.
            </p>
            {/* More paragraphs... */}
            <p className="text-dark">
                MUDRA 2K24 was not just a series of events; it was a celebration of unity, diversity, and excellence. It brought together students from different backgrounds and interests, fostering a sense of community and pride in our college. As we bid farewell to another memorable edition of MUDRA, we carry with us the cherished moments and friendships forged during these twelve days of festivity and joy.
            </p>

            <div className="row">
                <div className="col-12 col-md-3 mb-3">
                    {/* Display video file (MP4) */}
                    <video width="100%" controls style={imageStyle}>
                        <source src={img1} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
                <div className="col-12 col-md-3 mb-3">
                    <img src={img2} alt="MUDRA Event 2" className="img-fluid" style={imageStyle} />
                </div>
                <div className="col-12 col-md-3 mb-3">
                    <img src={img3} alt="MUDRA Event 3" className="img-fluid" style={imageStyle} />
                </div>
                <div className="col-12 col-md-3 mb-3">
                    <img src={img4} alt="MUDRA Event 4" className="img-fluid" style={imageStyle} />
                </div>

                <div className="col-12 col-md-3 mb-3">
                    <img src={img5} alt="MUDRA Event 5" className="img-fluid" style={imageStyle} />
                </div>
                {/* <div className="col-md-6">
                    <img src={img6} alt="MUDRA Event 6" className="img-fluid" style={imageStyle} />
                </div> */}

                <div className="col-12 col-md-3 mb-3">
                    <img src={img7} alt="MUDRA Event 7" className="img-fluid" style={imageStyle} />
                </div>
                <div className="col-12 col-md-3 mb-3">
                    <img src={img8} alt="MUDRA Event 8" className="img-fluid" style={imageStyle} />
                </div>

                <div className="col-12 col-md-3 mb-3">
                    <img src={img9} alt="MUDRA Event 9" className="img-fluid" style={imageStyle} />
                </div>
                <div className="col-12 col-md-3 mb-3">
                    <img src={img10} alt="MUDRA Event 10" className="img-fluid" style={imageStyle} />
                </div>

                <div className="col-12 col-md-3 mb-3">
                    <img src={img11} alt="MUDRA Event 11" className="img-fluid" style={imageStyle} />
                </div>
                <div className="col-12 col-md-3 mb-3">
                    <img src={img12} alt="MUDRA Event 12" className="img-fluid" style={imageStyle} />
                </div>
                <div className="col-12 col-md-3 mb-3">
                    <img src={img13} alt="MUDRA Event 13" className="img-fluid" style={imageStyle} />
                </div>
                <div className="col-12 col-md-3 mb-3">
                    <img src={img14} alt="MUDRA Event 14" className="img-fluid" style={imageStyle} />
                </div>
            </div>
        </div>
    );
};

export default Culturalevent;
