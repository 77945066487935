import React from "react";


import './bdcourse.css'




const Dpharmcourse = () => {
    return (
        <div className="container dpharmcourse mb-5 mt-3 d-flex align-items-center flex-column">
          <h2 className="course-head text-center">D.Pharm Course</h2>

          <div className="course-content">

            <h2 className="text-custom-green text-start">Eligibility Criteria</h2>

<h4 className="text-dark">Maharashtra State Candidature Candidates</h4>
<ul className="text-dark">
  <li>The Candidate should be an Indian National</li>
  <li>Passed 10+2 examination with Physics and Chemistry as compulsory subjects along with Mathematics/Biology subject</li>
  <li><strong>Note:</strong> Other State Candidates shall be eligible for Institution quota only</li>
</ul>

<h4 className="text-dark">NRI / OCI / PIO, Children of Indian workers in the Gulf countries, foreign national</h4>
<ul className="text-dark">
  <li>Passed 10+2 examination with Physics and Chemistry as compulsory subjects along with Mathematics/Biology</li>
  <li>Any other criterion declared from time to time by the appropriate authority as defined under the Rules.</li>
</ul>

<h2 className="text-custom-green text-start">Documents to be submitted for admission to D.Pharm (FC verified copies only)*</h2>
<ul className="text-dark">
  <li>Mark sheets of Std X & XII & Passing Certificate</li>
  <li>College Leaving Certificate / Transfer Certificate</li>
  <li>Domicile Certificate/Birth Certificate</li>
  <li>Income certificate for TFWS Scheme/ scholarship</li> text-start
  <li>Physically Handicapped/Disability certificate (with a Degree of handicap)</li>
  <li>Gap certificate (if applicable)</li>
  <li>Caste certificate and Caste Validity certificate</li>
  <li>Non-Creamy layer certificate</li>
  <li>Passport-size photographs (4 copies)</li>
  <li>Migration certificate for students other than Home University/Maharashtra State Board</li>
  <li>Affidavit of minority status (Mandatory for Minority Students)</li>
</ul>
<p className="text-dark">
  *All these are to be submitted as .pdf file and as three hard copies.
</p>

<h2 className="text-custom-green text-start">Curriculum/Course Credit Structure for D.Pharm</h2>
<p className="text-dark">
  The course is based on the guidelines issued by DTE. A student shall not be declared to have passed the Diploma in Pharmacy examination unless he/she secures a minimum of 40% in each subject, separately for theory and practicals (inclusive of sessional marks).
</p>

<h2 className="text-custom-green text-start">Scheme of assessment</h2>
<p className="text-dark"><strong>Note:</strong> In Sessional (theory/practical), three sessionals to be conducted, average of best two to be taken</p>

<table className="table table-bordered text-dark">
  <thead>
    <tr>
      <th>Class</th>
      <th>No. of Subjects</th>
      <th>Annual Exam (Theory/ Practical)</th>
      <th>Internal/ Sessional Exam (Theory/Practical)</th>
      <th>Total (Each Subject)</th>
      <th>Grand Total</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>1st year</td>
      <td>10 (5 Theory + 5 Practicals)</td>
      <td>80</td>
      <td>20</td>
      <td>100</td>
      <td>1000</td>
    </tr>
    <tr>
      <td>2nd year</td>
      <td>10 (5 Theory + 5 Practicals)</td>
      <td>80</td>
      <td>20</td>
      <td>100</td>
      <td>1100</td>
    </tr>
  </tbody>
</table>

        </div>

         </div> 

    );
};


export default Dpharmcourse