import React from "react";


import img1 from './assets/Gl/GL1-img1.jpg';
import img2 from './assets/Gl/GL1-img2.jpg';
import img3 from './assets/Gl/GL1-img3.jpg';
import img4 from './assets/Gl/GL1-img4.jpg';







const GuestPharmacology = () => {
    return(
        <div className="container mb-5 mt-5">
            <h1 className="text-custom-green text-center mb-4">
            Guest Lecture Report on Pharmacology
            </h1>
            <p className="text-dark">
            On behalf of B R Harne College of Pharmacy, Vangani, a guest lecture on Pharmacology was organized on Thursday, 08/08/2024. The session was conducted by Dr. Baban Thawkar, Assistant Professor of Pharmacology at Bharati Vidyapeeth College of Pharmacy, CBD Belapur, Navi Mumbai.
            </p>
            <li className="fs-4">Objective</li>
            <p className="text-dark">
            The objective of the lecture was to provide students with a deeper understanding of pharmacology, particularly with respect to its relevance for university and GPAT (Graduate Pharmacy Aptitude Test) exams.
            </p>
            <li className="fs-4">Key Highlights</li>
            <p className="text-dark">
            Anti-Hyperlipidemia Drugs: Dr. Thawkar explained the mechanism of action, therapeutic uses, and side effects of anti-hyperlipidemia drugs, highlighting their clinical importance.
            </p>
            <p className="text-dark">Exam Preparation: The speaker emphasized the importance of pharmacology concepts from both university and GPAT exam perspectives, offering tips on how to tackle related questions.</p>

            <li className="fs-4">Learning Outcomes</li>
            <p className="text-dark">
            Students gained comprehensive knowledge of anti-hyperlipidemia agents and their applications.
            </p>
            <p className="text-dark">
            The session improved their understanding of how to prepare for pharmacology-related questions in university and GPAT exams.   
            </p>
            <p className="text-dark">
            The lecture was highly informative and helped enhance students' grasp of pharmacology concepts, contributing to both academic and professional growth.    
            </p>
            <li className="fs-4">Vote of Thanks</li>
            <p className="text-dark">
            The vote of thanks was delivered by Mrs. Shraddha Khadse, expressing gratitude to Dr. Thawkar for his insightful presentation.
            </p>



            <div className="row">
                <div className="col-12 col-md-4 mb-4">
                    <img src={img1} className="img-fluid" alt="" />

                </div>
                <div className="col-12 col-md-4 mb-4">
                    <img src={img4} className="img-fluid" alt="" />

                </div>
                <div className="col-12 col-md-4 mb-4">
                    <img src={img3} className="img-fluid" alt="" />

                </div>
                <div className="col-12 col-md-4 mb-4">
                    <img src={img2} className="img-fluid" alt="" />

                </div>
            </div>
        </div>

    );
};



export default GuestPharmacology;