import React from "react";

import img1 from './assets/das/das1.jpeg';
import img2 from './assets/das/das2.jpeg';
import img3 from './assets/das/das3.jpeg';
import img4 from './assets/das/das4.jpeg';




const WorldAids = () => {

    const imageStyle = {
        width: '100%',
        height: '400px',
        objectFit: 'cover',
    };
    return(
        <div className="container mt-5 mb-5">
            <h1 className="text-custom-green text-center mb-4">
            Dashera Celebration (24/10/2023)

            </h1>
            <p className="text-dark">
            Dussehra, also known as Vijayadashami, is one of the most significant festivals celebrated by Hindus all around the globe. Marking the end of Navaratri, Dussehra symbolizes the victory of good over evil, encapsulating the essence of moral and ethical triumph. The festival is deeply embedded in Hindu mythology, commemorating two major events: the defeat of the demon king Ravana by Lord Rama, and the victory of Goddess Durga over the buffalo demon Mahishasura. These narratives not only enrich the cultural fabric of Hinduism but also impart crucial life lessons on righteousness, bravery, and the importance of moral integrity. This essay delves into the significance, celebration, and messages inherent in the festival of Dussehra.  
                            </p>

                            <p className="text-dark">
                            Dussehra's roots can be traced back to the ancient Hindu epics, the Ramayana and the Mahabharata, which illustrate the eternal battle between good and evil. In the northern, southern, and western parts of India, the festival predominantly celebrates Lord Rama's victory over Ravana, marking the triumph of justice and the restoration of dharma (moral order). The eastern parts of India, however, celebrate it as the day Goddess Durga defeated Mahishasura, symbolizing the empowerment of good and the destruction of negativity.
                                   </p>

                            <p className="text-dark">
                            Dussehra was celebrated on 24/10/2023 at B.R.Harne college of pharmacy vangani at celebration begins by worshipping the loddesss saraswati the instrument and equipment was worshipped with flowers at last sweet was distributed.
                            </p>

            <div className="row">
                <div className="col-12 col-md-3 mb-4">
                    <img src={img1} alt="" className="img-fluid" style={imageStyle}/>

                </div>
                <div className="col-12 col-md-3 mb-4">
                    <img src={img2} alt="" className="img-fluid" style={imageStyle}/>

                </div>
                <div className="col-12 col-md-3 mb-4">
                    <img src={img3} alt="" className="img-fluid" style={imageStyle}/>

                </div>
                <div className="col-12 col-md-3 mb-4">
                    <img src={img4} alt="" className="img-fluid" style={imageStyle}/>

                </div>
            </div>

        </div>
    );
};



export default WorldAids;