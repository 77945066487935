import React from "react";
import './Presidesk.css';
import president from './assets/brand/president.png';

const Presidesk = () => {
    return (
        <div className="container President-parent mb-5 mt-3">
            <div className="row mt-4">
                <div className="col-12 col-md-6 presi-img">
                    <img src={president} className="img-fluid rounded-circle" alt="President" />
                </div>

                <div className="col-12 col-md-6 presi-text">
                    <h2 className="text-custom-green from">From President's Desk</h2>
                    <h4 className="text-danger quote">"Courage and simplicity is the power to win"</h4>
                    <h2 className="text-custom-green name">Shri. Mangesh B. Harne</h2>
                    <p className="text-danger post">Hon'ble President</p>
                    <p className="ad">Jai Shree Siddhivinayak Foundation Dombivli (West)</p>

                </div>


            </div>


            <div className="row align-items-center mt-4">
                {/* <div className="col-12 text-center mb-4 mb-md-0">
                </div> */}
                <div className="col-12">

                    <p className="fs-p text-justify">
                        Jai Shree Siddhivinayak Foundation was established in the year 2007 with a social commitment to providing professional, competitive, and industry-relevant education to the younger generation of rural and urban areas. Globalization and liberalization have changed all the concerns and references. There is a need to reconsider the present education at all its levels and hence it is necessary to deviate from the traditional methods and use new methods and technology for imparting education.
                        <br /><br />
                        In today’s era of globalization, an organization needs qualified technocrats and managers filled with enthusiasm and a spirit of hard work blended with self-confidence to achieve their goals. At Jai Shree Siddhivinayak Foundation’s B. R. Harne College of Engineering and Technology, we have put together the organizational learning and insights gathered over time and combined them with innovative, state-of-the-art delivery mechanisms that will equip the younger generation to take on the challenges both in India and overseas.
                        <br /><br />
                        The institute has a very special place in my heart. Since 2022, BRHCET has developed excellent infrastructural facilities with well-equipped laboratories and an enriched library, which will be updated regularly. Similarly, I believe that in the near future, the institute will produce competent technocrats and managers who will make a significant contribution to the corporate world and industries all over the world. All the Best & Welcome!
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Presidesk;
