import React, { useState } from "react";
import "./Updates.css";
import { useSwipeable } from "react-swipeable";

import sposter1 from "./assets/posters/brhpost1.jpeg";
import sposter2 from "./assets/posters/brhpost2.jpeg";
import sposter3 from "./assets/posters/brhpost3.jpeg";
import lposter1 from "./assets/posters/Addmition.png";
import lposter2 from "./assets/posters/addopen.jpg";
import lposter3 from "./assets/posters/rangoli.jpeg";

const Updates = () => {
  const posters = [sposter1, sposter2, sposter3, lposter1, lposter2, lposter3];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [lightboxImage, setLightboxImage] = useState("");

  const goToPrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? posters.length - 1 : prevIndex - 1
    );
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === posters.length - 1 ? 0 : prevIndex + 1
    );
  };

  const openLightbox = (poster) => {
    setLightboxImage(poster);
    setIsLightboxOpen(true);
  };

  const closeLightbox = () => {
    setIsLightboxOpen(false);
    setLightboxImage("");
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: goToNext,
    onSwipedRight: goToPrev,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  }); 

  return (
    <div className="updates" {...swipeHandlers}>
      <div className="updates-parent d-flex align-items-center flex-column">
        {/* Title and Navigation */}
        <h3>Latest Updates</h3>
        {/* <div className="arrow-buttons d-flex justify-content-end w-100">
  <button onClick={goToPrev} className="custom-arrow-btn">
    <i className="fa-solid fa-chevron-left"></i>
  </button>
  <button onClick={goToNext} className="custom-arrow-btn">
    <i className="fa-solid fa-chevron-right"></i>
  </button>
</div> */}


        {/* Image Display */}
        <div className="poster-container">
          {Array.from({ length: window.innerWidth > 768 ? 3 : 1 }).map((_, i) => {
            const index = (currentIndex + i) % posters.length;
            return (
              <div
                key={index}
                className="img-container"
                onClick={() => openLightbox(posters[index])}
              >
                <img
                  src={posters[index]}
                  alt={`Poster ${index + 1}`}
                  className="poster-img"
                />
              </div>
            );
          })}
        </div>
        <div className="arrow-buttons d-flex justify-content-center w-100">
  <button onClick={goToPrev} className="custom-arrow-btn">
    <i className="fa-solid fa-chevron-left"></i>
  </button>
  <button onClick={goToNext} className="custom-arrow-btn">
    <i className="fa-solid fa-chevron-right"></i>
  </button>
</div>
      </div>

      {/* Lightbox Modal */}
      {isLightboxOpen && (
        <div className="lightbox-overlay" onClick={closeLightbox}>
          <div className="lightbox-content">
            <img src={lightboxImage} alt="Lightbox" className="lightbox-img" />
          </div>
        </div>
      )}
    </div>
  );
};

export default Updates;
